import * as React from "react"
import { Helmet } from "react-helmet"

import IDALayout from "../../components/layouts/layout-ida"
import Seo from "../../components/seo"

import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"

import ReimbursementIcon from "../../images/hp/access/reimbursement.png"
import PapIcon from "../../images/hp/access/pap.png"
import CopayIcon from "../../images/hp/access/copay.png"

import akbcares from "../../images/AkebiaCares-logo.png"
import AkebiaCaresPDF from "../../pdfs/AkebiaCares-Copay-Coupon.pdf"
import EnrollmentFormPDF from "../../pdfs/AkebiaCares-Enrollment-Form.pdf"

import bottle1 from "../../images/ida/access/icon-one-bottle.png"
import bottle2 from "../../images/ida/access/icon-two-bottles.png"
import bottle3 from "../../images/ida/access/icon-three-bottles.png"

const AccessPage = ({ location }) => (
    <IDALayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1408 (v4.0)"
        jobCodeDate="09/24"
        references={[
            <>Data on File 24, Akebia Therapeutics, Inc.</>,
            <>Data on File 29, Akebia Therapeutics, Inc.</>,
            <>
                AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia
                Therapeutics, Inc.; 2021.
            </>,
        ]}
    >
        <Helmet>
            <html lang="en" />
            <link
                rel="canonical"
                href="https://www.auryxiahcp.com/iron-deficiency-anemia/access"
            />
            <body className="ida" id="ida-hcp-access" />
        </Helmet>

        <Seo
            title="AkebiaCares"
            description="Discover the access resources available for AURYXIA tablets through AkebiaCares. See Prescribing Information and Full Safety Information."
        />
        <section id="hero">
            <div className="flex-row">
                <div>
                    <img
                        style={{ maxWidth: "200px" }}
                        src={akbcares}
                        alt="AkebiaCares"
                    />
                </div>
                <div className="partnerDiv">
                    <h1 className="akb">
                        Your partner in helping patients access the medication
                        they need
                    </h1>
                </div>
            </div>
        </section>
        <section id="gradient-block">
            <div
                id="quote"
                className="flex-row"
                data-sal="zoom-in"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <blockquote>
                        <p className="blockquote_p">
                            <span>&#x201C; </span> They are extremely easy to
                            work with. I&nbsp;am very thankful for having that
                            support for my patients and them always being
                            available.<span>&#x201d;</span>
                        </p>
                        <cite>
                            &mdash; <strong>Constance Nicastro-Bowman</strong>,
                            CNP Nurse Practitioner
                        </cite>
                    </blockquote>
                </div>
            </div>

            <div id="coverage-circle" className="flex-row">
                <div
                    data-sal="slide-up"
                    data-sal-duration="600"
                    data-sal-delay="100"
                    data-sal-ease="ease-out-expo"
                >
                    <h2>
                        AURYXIA is on formulary with the majority&nbsp;of
                        commercial&nbsp;plans<sup>1</sup>
                    </h2>
                    <p>
                        With the AURYXIA Copay Coupon, eligible patients can pay
                        as little as <strong>$0 per fill</strong>{" "}
                        and&nbsp;may&nbsp;receive*:
                    </p>

                    <div className="flex-row">
                        <div>
                            <img src={bottle1} alt="For 90 tablets or less" />
                            <p>
                                <strong>For 90 tablets or less</strong>
                                <br />
                                Up to $500 off prescriptions
                            </p>
                        </div>

                        <div>
                            <img src={bottle2} alt="For 91-181 tablets" />
                            <p>
                                <strong>For 91-181 tablets</strong>
                                <br />
                                Up to $1,000 off prescriptions
                            </p>
                        </div>
                        <div>
                            <img src={bottle3} alt="For 181 tablets or more" />
                            <p>
                                <strong>For 181 tablets or more</strong>
                                <br />
                                Up to $1,500 off prescriptions
                            </p>
                        </div>
                    </div>

                    <p className="footnote">
                        *AURYXIA is supplied as bottles of 200-count 210 mg
                        ferric iron tablets.<sup>2</sup> Restrictions may apply.
                        Copay assistance is not valid for prescriptions
                        reimbursed under Medicare, Medicaid, or similar federal
                        or state programs.
                    </p>
                    <hr />
                    <p>
                        <strong>Free AURYXIA</strong> may be provided to
                        patients<sup>1</sup>:
                    </p>
                    <ul>
                        <li>With no insurance</li>
                        <li>Whose insurance does not cover AURYXIA</li>
                    </ul>
                    <p>
                        <a
                            className="cta akb"
                            href="https://www.akebiacareshcp.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            FIND SUPPORT OPTIONS
                        </a>
                    </p>
                </div>
            </div>

            <br />
        </section>

        <section id="akebia-slab">
            <div
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <h2 className="akb">
                        Eligible patients can get AURYXIA for{" "}
                        <strong>little&nbsp;or no cost</strong>*
                        <sup>&#x2020;</sup>
                    </h2>

                    <div className="flex-row">
                        <div className="icon">
                            <img
                                src={ReimbursementIcon}
                                alt="REIMBURSEMENT HELP"
                            />
                        </div>
                        <div className="content">
                            <h3>REIMBURSEMENT HELP</h3>
                            <p>
                                One-on-one support for assistance with insurance
                                questions and challenges
                            </p>
                        </div>
                    </div>

                    <div className="flex-row">
                        <div className="icon">
                            <img
                                src={PapIcon}
                                alt="PATIENT ASSISTANCE PROGRAMS (PAP)"
                            />
                        </div>
                        <div className="content">
                            <h3>PATIENT ASSISTANCE PROGRAMS (PAP)</h3>
                            <p>
                                For patients who are uninsured or have other
                                coverage concerns, AkebiaCares offers
                                comprehensive patient services that may be able
                                to help
                            </p>
                        </div>
                    </div>

                    <div className="flex-row">
                        <div className="icon">
                            <img src={CopayIcon} alt="COPAY COUPON" />
                        </div>
                        <div className="content">
                            <h3>COPAY COUPON</h3>
                            <p>
                                Financial assistance for patients with
                                commercial insurance<sup>&#x2021;</sup>
                            </p>
                            <ul className="download_copay_ul">
                                <li>
                                    Visit AkebiaCaresHCP.com for copay
                                    enrollment
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <EopCalloutCards
            siteAudience="akb"
            callOneClass={"highlight"}
            callOneLink={
                "https://akebia.aspnprograms.com/(S(ycaawahbq1wu0ygt24kweoau))/"
            }
            newTabOne="_blank"
            callOneContent={
                <>
                    <h3>Find pharmacies that dispense AURYXIA</h3>
                    <p>
                        Search by ZIP code to locate an AURYXIA network
                        specialty pharmacy.
                    </p>
                    <button className="cta akb">
                        <span>GET STARTED</span>
                    </button>
                </>
            }
        />

        <section className="col-10 footnote_row">
            <div className="flex-row">
                <div>
                    <p className="footnote" style={{ marginLeft: "-6px" }}>
                        <span style={{ marginLeft: "-6px" }}>*</span>AURYXIA is
                        supplied as bottles of 200-count 210 mg ferric iron
                        tablets.<sup>2</sup> Restrictions may apply. Copay
                        assistance is not valid for prescriptions reimbursed
                        under Medicare, Medicaid, or similar federal or state
                        programs.
                    </p>
                    <p className="footnote" style={{ marginLeft: "-5px" }}>
                        <span style={{ marginLeft: "-5px" }}>
                            <sup>&#x2020;</sup>
                        </span>
                        Patients with an income of up to 600% Federal Poverty
                        Level (FPL) may be eligible for free AURYXIA. Additional
                        restrictions apply.
                        <br />
                        <strong>
                            AkebiaCares does not guarantee coverage and/or
                            reimbursement for all patients.
                        </strong>
                    </p>
                    <p className="footnote" style={{ marginLeft: "-5px" }}>
                        <span style={{ marginLeft: "-5px" }}>
                            <sup>&#x2021;</sup>
                        </span>
                        Copay assistance is not valid for prescriptions
                        reimbursed under Medicare, Medicaid, or similar federal
                        or state programs.
                    </p>
                </div>
            </div>
        </section>
    </IDALayout>
)

export default AccessPage
